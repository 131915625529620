import React, {useEffect, useState} from 'react'
import {Box, CircularProgress, Grid, TableCell, TableRow, Typography} from "@mui/material";
import ActionButton from "../../../../../components/ActionButton/ActionButton";
import ManageAccountsLiveTable from "./ManageAccountsLiveTable";
import {MANAGE_ACCOUNTS_OPEN_ACCOUNT_FORM, MANAGE_ACCOUNTS_TRANSFER_FORM} from "../../../../../constants/constants";
import {useDispatch, useSelector} from "react-redux";
import {getAccountsList} from "../../../../../store/reducers/accounts/actions";
import {selectLiveAccounts} from "../../../../../store/reducers/accounts/selectors";
import {refreshToken2} from "../../../../../store/reducers/auth/actions";

const ManageAccountsLive = ({openForm}) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(true)
	const accounts = useSelector(selectLiveAccounts)
	const fetchAccounts = async() => {
		try{
			setLoading(true)
			await dispatch(getAccountsList('Real'))
		}catch(error){
			console.log('error', error)
		}finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchAccounts()
	},[])

	return(
		<Box height={'85%'}>
			<Grid container columnSpacing={5} rowSpacing={3}>
				<Grid item xs={12} md={6}>
					<ActionButton 
						title={'Transfer between accounts'}
						sx={{ p:'10px'}}
						color={'secondary'}
						onClick={() => openForm(MANAGE_ACCOUNTS_TRANSFER_FORM)}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ActionButton 
						title={'Open new account'} 
						sx={{p:'10px'}}
						onClick={() => openForm(MANAGE_ACCOUNTS_OPEN_ACCOUNT_FORM)}
					/>
				</Grid>
			</Grid>
			{loading && (
				<Box textAlign={'center'} mt={5}>
					<CircularProgress/>
				</Box>
			)}
			{
				!loading && accounts.length !== 0 &&
			<ManageAccountsLiveTable>
				{accounts ? accounts.map((row) => (
					<TableRow
						key={row.trading_account_id}
					>
						<TableCell component="th" scope="row">
							#{row.trading_account_id}
						</TableCell>
						<TableCell align="left">{row.margin_leverage}</TableCell>
						<TableCell align="left">{row.currency}</TableCell>
						<TableCell align="left">{row.equity}</TableCell>
						<TableCell align="left">{row.balance}</TableCell>
						<TableCell align="left">{row.credit}</TableCell>
						<TableCell align="left">{row.enabled === true ? 'Active' : 'Inactive'}</TableCell>
						<TableCell align="left">{row.read_only === true ? 'True' : 'False'}</TableCell>
					</TableRow>
				)) : ''}
			</ManageAccountsLiveTable>
			}
			{
				!loading && accounts.length === 0 &&
				<Box>
					<Typography sx={{color: 'white'}}> No live accounts</Typography>
				</Box>
			}
		</Box>
	)
}
export default ManageAccountsLive