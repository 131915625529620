import React, {useEffect, useRef, useState} from 'react'
import {Box, CircularProgress, FormHelperText, Grid, Typography} from "@mui/material";
import AppFormControl from "../../../../../components/AppForm/AppFormControl";
import InputPassword from "../../../../../components/AppForm/InputPassword";
import {useForm, useWatch} from "react-hook-form";
import ActionButton from "../../../../../components/ActionButton/ActionButton";
import {REGEX_PASSWORD_VALIDATION} from "../../../../../constants/constants";
import {clientDetails} from "../../../../../api/clientDetails";
import AppModal from "../../../../../components/AppModal/AppModal";
import {useDispatch} from "react-redux";
import {refreshToken2} from "../../../../../store/reducers/auth/actions";

const AccountDetailsPassword = () => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [modalContent, setModalContent] = useState({
		title: '',
		text: ''
	})
	
	const {
		register,
		reset,
		formState: {errors},
		formState,
		handleSubmit,
		watch,
		trigger,
	} = useForm({
		mode: "all",
	})
	
	const newPassword = watch('newPassword', '')
	
	const changePassword = async (requestBody) => {
		try{
			const token = sessionStorage.getItem('access_token')
			const res = await clientDetails.changePassword(token, requestBody)
			reset()
			setShowModal(true)
			setModalContent({title: 'Success', text: 'Your password is successfully changed'})
		} catch(error) {
			if(error.response.status === 401){
				await dispatch(refreshToken2())
				return changePassword(requestBody)
			} else {
				setShowModal(true)
				setModalContent({title: 'Error', text: error.response.data.details || error.response.data.detail})
			}
		}

	}
	const onSubmit = async ( data ) => {
		setLoading(true)
		const { oldPassword, newPassword, repeatedPassword} = data
		const requestBody = {
			current_password: oldPassword,
			new_password: newPassword,
			new_password_confirmation: repeatedPassword
		}
		await changePassword(requestBody)
			.finally(() => setLoading(false))
	}
	
	useEffect(() => {
		trigger('repeatedPassword')
	}, [newPassword])

	const handleCloseModal = () => {
		setShowModal(false)
	}
	
	return (

		<Box
			component={'form'}
			onSubmit={handleSubmit(onSubmit)}
		>
			<Typography variant={'body2'} mb={'20px'}>
				You may set a new password for your account here.
				It is strongly recommended to select a password that contains Latin characters and digits combined,
				and cannot be easily guessed.
			</Typography>
			<Box
				component={'fieldset'}
				border={'none'}
				p={0}
			>
				<Grid container >
					<Grid item xs={12} md={6}>

						<AppFormControl sx={{mb: '35px'}}>
							<InputPassword
								id={'oldPassword'}
								label={'Old Password*'}
								name={'oldPassword'}
								{...register('oldPassword', {
									required: 'You must enter old password.',
								})}
								error={!!errors.oldPassword}
							/>
							{errors.oldPassword && <FormHelperText error id={'newPassword'}>
								{errors.oldPassword.message}
							</FormHelperText>}
						</AppFormControl>
					</Grid>
				</Grid>
				<Grid container >
					<Grid item xs={12} md={12}>
						<Typography variant={'body2'} mb={'20px'}>
							Your password should have at least 8 symbols, but not more than 12 symbols, should contain
							at
							least: 1 uppercase letter, 1 lowercase letter, 1 digit
						</Typography>
					</Grid>
				</Grid>
				<Grid container >

					<Grid item xs={12} md={6}>

						<AppFormControl sx={{mb: '35px'}}>
							<InputPassword
								id={'newPassword'}
								label={'New Password*'}
								name={'newPassword'}
								{...register('newPassword', {
									required: 'You must specify password.',
									minLength: {
										value: 8, message: 'Password must have at least 8 characters.'
									},
									pattern: REGEX_PASSWORD_VALIDATION,
									maxLength: {
										value: 12, message: 'Password must contain up to 12 characters.'
									}
								})}
								error={!!errors.newPassword}

							/>
							{errors.newPassword && <FormHelperText error id={'newPassword'}>
								{errors.newPassword.message}
							</FormHelperText>}
						</AppFormControl>
					</Grid>
				</Grid>
				<Grid container>

					<Grid item xs={12} md={6}>

						<AppFormControl sx={{mb: '35px'}}>
							<InputPassword
								id={'repeatedPassword'}
								label={'Repeat Password*'}
								name={'repeatedPassword'}
								{...register('repeatedPassword', {
									validate: value => {
										return value === newPassword || 'The value is not equal to the New Password'
									}
								})}
								error={!!errors.repeatedPassword}
							/>
							{errors.repeatedPassword && <FormHelperText error id={'repeatedPassword'}>
								{errors.repeatedPassword.message}
							</FormHelperText>}
						</AppFormControl>

						<ActionButton
							title={'Save Changes'}
							sx={{p: '10px'}}
							type={'submit'}
							disabled={!formState.isValid || loading}
						>
							{loading && (
								<CircularProgress
									size={28}
									sx={{
										color: 'primary',
										position: 'absolute',
										top: '50%',
										left: '50%',
										marginTop: '-12px',
										marginLeft: '-12px',
									}}
								/>
							)}
						</ActionButton>
					</Grid>
				</Grid>
				<AppModal isOpen={showModal} title={modalContent.title} errorText={modalContent.text}
				          handleClose={handleCloseModal}/>
			</Box>
		</Box>
	)
}

export default AccountDetailsPassword