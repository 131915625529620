import React, {useEffect, useState} from 'react'
import {Box, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, Typography} from "@mui/material";
import AppFormControl from "../../../../../components/AppForm/AppFormControl";
import AppFormFieldInput from "../../../../../components/AppForm/AppFormFieldInput";
import theme from "../../../../../theme";
import ActionButton from "../../../../../components/ActionButton/ActionButton";
import AppFormSelectWithoutDefault from "../../../../../components/AppForm/AppFormSelectWithoutDefault";
import {useForm} from "react-hook-form";
import {manageAccountsApi} from "../../../../../api/manageAccounts";
import AppModal from "../../../../../components/AppModal/AppModal";
import {useDispatch, useSelector} from "react-redux";
import {getAccountsList} from "../../../../../store/reducers/accounts/actions";
import {selectAllAccounts} from "../../../../../store/reducers/accounts/selectors";
import {refreshToken2} from "../../../../../store/reducers/auth/actions";

const ManageAccountsTransferForm = ({closeForm}) => {
	
	const dispatch = useDispatch()
	const [checkboxValue, setCheckboxValue] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [loading, setLoading] = useState(true)
	
	const accounts = useSelector(selectAllAccounts).map(item => {
		return {
			title:`#${item.trading_account_id} Type:${item.type_of_account} Balance: ${item.currency}${item.balance}${item.read_only === true ? ' Read only' : ''}`, id: item.trading_account_id
		}
	})
	const [transferForm, setTransferForm] = useState({
		accountFromId: '',
		accountToId: '',
		amount: ''
	})
	const [modalContent, setModalContent] = useState({
		title: '',
		text: ''
	})
	const {handleSubmit, setValue} = useForm({
		mode: 'all',
		defaultValues: {
			accountFromId: '',
			accountToId: '',
			amount: ''
		}
	})
	const fetchAccounts = async () => {
		
		try{
			setLoading(true)
			await dispatch(getAccountsList())
		} catch( error) {
				console.log('ereer', error)
				setModalContent({title: 'Error', text: error.response.data.detail || error.response.data.details})
		}finally {
			setLoading(false)
		}
	}
	
	const isValid = transferForm.accountFromId &&
					transferForm.accountToId &&
					Number(transferForm.amount) &&
					checkboxValue
	const handleChangeTransferForm = (event, inputName) => {
		const formCopy = {...transferForm}
		formCopy[inputName] = event.target.value
		setTransferForm(formCopy)
		setValue(inputName, event.target.value)
	}
	
	const handleChangeCheckbox = (event) => {
		setCheckboxValue(event.target.checked)
	}
	const createTransfer = async(data) => {
		const { accountFromId, accountToId, amount} = data
		const body = {
			account_from_id: accountFromId,
			account_to_id: accountToId,
			amount_in_eur: Number(amount),
		}
		try{
			const token = sessionStorage.getItem('access_token')
			const res = await manageAccountsApi.createTransferBetweenAccounts(token, body)
				.then(() => {
						setShowModal(true)
						setModalContent({title: 'Success', text: 'Transfer is successfully performed'})
					}
				)
		}catch(error) {
			console.log('error', error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return createTransfer(data)
			}else{
				setShowModal(true)
				setModalContent({title: 'Error', text: error.response.data.detail || error.response.data.details || error.message})
			}
		}
	}
	const onSubmit = (data) => {
		createTransfer(data)
	}

	const handleCloseModal = () => {
		setShowModal(false)
		closeForm()
	}
	
	useEffect(() => {
		fetchAccounts()
	}, [])
	
	return(
		<Box>
			<Typography variant={'subtitle2'} mb={'40px'}>
				Transfer between accounts
			</Typography>
			<Box 
				component={'form'}
				onSubmit={handleSubmit(onSubmit)}
			>
				<Grid container>
					<Grid item xs={12} md={5.5}>
						<AppFormControl sx={{mb:'25px'}}>
							<AppFormSelectWithoutDefault
								label={'Transfer from account*'}
								onChange={(event) => handleChangeTransferForm(event, 'accountFromId')}
								value={transferForm.accountFromId}
							>
								{loading && (
									<Box textAlign={'center'} >
										<CircularProgress size={25}/>
									</Box>
								)}
								{
									!loading && accounts ?
										accounts.map(item => (
											<MenuItem
												key={item.id}
												value={item.id}
												sx={{fontFamily:'Roboto,sans-serif'}}
												disabled={transferForm.accountToId === item.id}

											>

												{item.title}
											</MenuItem>
										)) : ''
								}
							</AppFormSelectWithoutDefault>
						</AppFormControl>
						<AppFormControl sx={{mb:'25px'}}>
							<AppFormSelectWithoutDefault
								label={'Transfer to account*'}
								onChange={(event) => handleChangeTransferForm(event, 'accountToId')}
								value={transferForm.accountToId}
							>
								{loading && (
									<Box textAlign={'center'}>
										<CircularProgress size={25}/>
									</Box>
								)}
								{
									!loading && accounts ?
										accounts.map(item => (
											<MenuItem
												key={item.id}
												value={item.id}
												sx={{fontFamily:'Roboto,sans-serif'}}
												disabled={transferForm.accountFromId === item.id}
											>
												{item.title}
											</MenuItem>
										)) : ''
								}
							</AppFormSelectWithoutDefault>
						</AppFormControl>
						<AppFormControl sx={{mb: '15px'}}>
							<AppFormFieldInput
								label={'Amount in EUR*'}
								type={'number'}
								value={transferForm.amount}
								onChange={(event) => handleChangeTransferForm(event, 'amount')}
								InputProps={{
									inputProps: { min: 0 }
								}}
								onKeyPress={(event) => {
									if (event?.key === '-' || event?.key === '+' || event?.key === 'e') {
										console.log('events key', event.key)
										event.preventDefault();
									}
								}}
							/>
						</AppFormControl>
						<FormControlLabel 
							onChange={handleChangeCheckbox}
							control={<Checkbox sx={{
								color: 'white',
								'&.Mui-checked': {
									color: theme.palette.bgColors.color4,
								},
							}} color={'secondary'} />} 
							label="I confirm to perform a transfer" 
							sx={{
								mb: '15px',
								'& .MuiFormControlLabel-label': {
									color: 'white'
								}}}
						/>
						<Box display={'flex'} gap={'8px'}>
							<ActionButton
								title={'Cancel'}
								color={'secondary'}
								onClick={() => closeForm()}
							/>
							<ActionButton
								type={'submit'}
								title={'Make transfer'}
								sx={{p: '11px 9px'}}
								disabled={!isValid}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<AppModal isOpen={showModal} title={modalContent.title} errorText={modalContent.text}
			          handleClose={handleCloseModal}/>
		</Box>
	)
}

export default ManageAccountsTransferForm