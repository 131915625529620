import React from 'react'
import {Box, Tab, Tabs} from "@mui/material";
import theme from "../../../../../theme";
import {ACCOUNT_DETAILS_INFO_ID, ACCOUNT_DETAILS_PASSWORD_ID} from "../../../../../constants/constants";

const accountDetailsTabs = [
	{
		id: ACCOUNT_DETAILS_INFO_ID,
		title: 'PERSONAL INFORMATION'
	},
	{
		id: ACCOUNT_DETAILS_PASSWORD_ID,
		title: 'CHANGE PASSWORD'
	}
]
const  AccountDetailsNav = ({handleClickTab}) =>  {
	const [value, setValue] = React.useState(ACCOUNT_DETAILS_INFO_ID);
	const innerWidth = window.innerWidth
	
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	
	return (
		<Box mb={'40px'} ml={{xs:'-20px', md:0}} mr={'-20px'}>
			<Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example" centered={innerWidth < 900}>
				{accountDetailsTabs ?
					accountDetailsTabs.map(tab => (
						<Tab
							key={tab.id}
							value={tab.id} 
							label={tab.title} 
							sx={{pb: '0', fontSize:{xs: '12px', md: '14px'}}}
							onClick={() => handleClickTab(tab.id)}
						/>
					)) : ''
				}
			</Tabs>
		</Box>
	)
}

export default AccountDetailsNav