/**
 * IDs for dashboard
 */
export const TRANSACTION_HISTORY_ID = '1'
export const ACCOUNT_DETAILS_ID = '2'
export const WITHDRAW_ID = '3'
export const MANAGE_ACCOUNTS_ID = '4'
export const DEPOSIT_ID = '5'
export const WEB_TRADER_ID = '6'

/**
 * IDs for account details tab
 */
export const ACCOUNT_DETAILS_INFO_ID = '1'
export const ACCOUNT_DETAILS_PASSWORD_ID = '2'

/**
 * IDs for withdraw tabs
 */
export const WITHDRAW_REQUEST_ID = '1'
export const WITHDRAW_HISTORY_ID = '2'

/**
 * IDs for manage accounts tabs
 */
export const MANAGE_ACCOUNTS_LIVE_ID = '1'
export const MANAGE_ACCOUNTS_DEMO_ID = '2'

/**
 * IDs for forms in manage accounts tab
 */
export const MANAGE_ACCOUNTS_TRANSFER_FORM = '1'
export const MANAGE_ACCOUNTS_OPEN_ACCOUNT_FORM = '2'


export const REGEX_PASSWORD_VALIDATION = {
	value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
	message: 'Wrong format of password'
}

export const PERIOD_FILTER_VALUES = [
	{
		title:'All period',
		value: ''
	},
	{
		title:'Today',
		value: 'today'
	},
	{
		title:'Last 3 days',
		value: 'last_3_days'
	},
	{
		title:'Last week',
		value: 'last_week'
	},
	{
		title:'Last month',
		value: 'last_month'
	},
	{
		title:'Last 3 month',
		value: 'last_3_month'
	}
]
export const TRANSACTION_TYPE_FILTER_VALUES = [
	{
		title:'All',
		value:''
	},
	{
		title:'Deposit',
		value:'d'
	},
	{
		title:'Withdrawal',
		value:'w'
	},
	{
		title:'Credit in',
		value:'ci'
	},
	{
		title:'Credit out',
		value:'co'
	},
]
export const WITHDRAW_TRANSACTIONS_STATUSES = [
	{
		title:'All',
		value: ''
	},
	{
		title:'Pending',
		value: 'pending'
	},
	{
		title:'Approved',
		value: 'approved'
	},
	{
		title:'Cancelled',
		value: 'cancelled'
	},
	{
		title:'Rejected',
		value: 'rejected'
	},
	
]

export const CURRENCY_SIGNS = {
	'EUR': '€',
	'USD': '$',
	'GBP': '£'
}

export const TRANSACTION_TYPES ={
	'd': 'Deposit',
	'w': 'Withdrawal',
	'ci': 'Credit in',
	'co': 'Credit out'
}

export const WITHDRAW_STATUSES = {
	'p': 'Pending',
	'a': 'Approved',
	'r': 'Rejected',
	'c': 'Cancelled',
	'cm': 'Cancelled',
	'cc': 'Cancelled',
}