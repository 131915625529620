import React, {useState} from 'react'
import {MANAGE_ACCOUNTS_DEMO_ID, MANAGE_ACCOUNTS_LIVE_ID} from "../../../../../constants/constants";
import {Box, Tab, Tabs} from "@mui/material";
import theme from "../../../../../theme";


const manageAccountsTabs = [
	{
		id: MANAGE_ACCOUNTS_LIVE_ID,
		title: 'LIVE'
	},
	{
		id:MANAGE_ACCOUNTS_DEMO_ID,
		title: 'DEMO'
	}
]

const ManageAccountsNav = ({handleClickTab, openedTab}) => {
	
	const [value, setValue] = useState(openedTab);
	const innerWidth = window.innerWidth
	
	const handleChange = (event, newValue) => {
		setValue(newValue)
	}
	
	return(
		<Box mb={'40px'} ml={{xs:'-20px', md:0}} mr={'-20px'}>
			<Tabs value={value} onChange={handleChange} aria-label="manage accounts tabs" centered={innerWidth < 900}>
				{manageAccountsTabs ?
					manageAccountsTabs.map(tab => (
						<Tab
							key={tab.id}
							value={tab.id}
							label={tab.title}
							sx={{pb: '0', fontSize:{xs: '12px', md: '14px'}}}
							onClick={() => handleClickTab(tab.id)}
						/>
					)) : ''
				}
			</Tabs>
		</Box>
	)
}

export default ManageAccountsNav