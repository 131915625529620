import React, {useEffect, useState} from 'react'
import {Box, CircularProgress, Grid, MenuItem, Typography} from "@mui/material";
import {ContentBox} from "../../../../components/ContentBox";
import AppFormControl from "../../../../components/AppForm/AppFormControl";
import {PERIOD_FILTER_VALUES, TRANSACTION_TYPE_FILTER_VALUES} from "../../../../constants/constants";
import TransactionHistoryTable from "./components/TransactionHistoryTable";
import AppFormSelectWithDefault from "../../../../components/AppForm/AppFormSelectWithDefault";
import {useDispatch, useSelector} from "react-redux";
import {getAllTransactionsList} from "../../../../store/reducers/transactions/actions";
import {selectAllTransactions} from "../../../../store/reducers/transactions/selectors";
import {refreshToken2} from "../../../../store/reducers/auth/actions";
import {getAccountsList} from "../../../../store/reducers/accounts/actions";
import {selectAllAccounts} from "../../../../store/reducers/accounts/selectors";

const TransactionHistory = () => {
	const dispatch = useDispatch()
	const [filters, setFilters] = useState({
		periodFilterValue: '',
		typesFilterValue: '',
		accountFilterValue: ''
	})
	const accountsList = useSelector(selectAllAccounts).map(item => item.trading_account_id)
	const [tableLoading, setTableLoading] = useState(true)
	const [isMounted, setIsMounted] = useState(false)
	const transactions = useSelector(selectAllTransactions)
	const fetchAccounts = async () => {
		try {
			setIsMounted(false)
			await dispatch(getAccountsList())
		} catch (error) {
			console.log('error', error)
		}finally {
			setIsMounted(true)
		}
	}
	
	const fetchAllTransactions = async (params) => {
		try{
			await dispatch(getAllTransactionsList(params))
		} catch (error) { 
			console.log(error)
		} finally {
			setTableLoading(false)
		}
	}
	useEffect(() => {
			fetchAccounts()
				.catch(console.error)
	}, [])

	useEffect(() => {
		
		setTableLoading(true)
		const params = {
			limit: undefined,
			offset: undefined,
			created_from: undefined,
			created_before: undefined,
			transaction_types: filters.typesFilterValue || undefined,
			payment_types: undefined,
			currencies: undefined,
			trading_account_id: filters.accountFilterValue || undefined,
			period: filters.periodFilterValue || undefined
		}
		if(isMounted){
			fetchAllTransactions(params)
				.catch(console.error)
		}else return
			
		
	}, [isMounted, filters])

	const handleSetFilterValue = (event, filterName) => {
		const filtersCopy = {...filters}
		filtersCopy[filterName] = event.target.value
		setFilters(filtersCopy)
	}

	return (
		<ContentBox sx={{
			flexDirection: 'column',
			overflow: 'hidden'
		}}
		>
			<Grid container columnSpacing={4} rowSpacing={3} columns={12}>
				<Grid item xs={12} md={4} lg={3}>
					<AppFormControl>
						<AppFormSelectWithDefault
							label={'Period'}
							id={'periodSelect'}
							value={filters.periodFilterValue}
							onChange={(event) => handleSetFilterValue(event, 'periodFilterValue')}
						>
							{ PERIOD_FILTER_VALUES ? PERIOD_FILTER_VALUES.map(option => (
								<MenuItem
									key={option.value}
									value={option.value}
									sx={{fontFamily:'Roboto,sans-serif'}}
									disabled={filters.periodFilterValue === option.value}
								>
									{option.title}
								</MenuItem>
							)) : 'No data'}
						</AppFormSelectWithDefault>
					</AppFormControl>
				</Grid>
				<Grid item xs={12} md={4} lg={3}>
					<AppFormControl>
						<AppFormSelectWithDefault
							label={'Type'}
							id={'typesSelect'}
							options={TRANSACTION_TYPE_FILTER_VALUES}
							value={filters.typesFilterValue}
							onChange={(event) => handleSetFilterValue(event, 'typesFilterValue')}
						>
							{ TRANSACTION_TYPE_FILTER_VALUES ? TRANSACTION_TYPE_FILTER_VALUES.map(option => (
								<MenuItem
									key={option.value}
									value={option.value}
									sx={{fontFamily:'Roboto,sans-serif'}}
									disabled={filters.typesFilterValue === option.value}
								>
									{option.title}
								</MenuItem>
							)) : 'No data'}
						</AppFormSelectWithDefault>
					</AppFormControl>
				</Grid>
				<Grid item xs={12} md={4} lg={3}>
					<AppFormControl>
						<AppFormSelectWithDefault
							label={'Account'}
							id={'accountSelect'}
							options={accountsList}
							value={filters.accountFilterValue}
							onChange={(event) => handleSetFilterValue(event, 'accountFilterValue')}
						>
							<MenuItem
								value={''}
								sx={{fontFamily: 'Roboto,sans-serif'}}
								disabled={filters.accountFilterValue === ''}
							>
								All
							</MenuItem>
							{ accountsList ? accountsList.map(option => (
								<MenuItem
									key={option}
									value={option}
									sx={{fontFamily:'Roboto,sans-serif'}}
									disabled={filters.accountFilterValue === option}
								>
									#{option}
								</MenuItem>
							)) : 'No data'}
						</AppFormSelectWithDefault>
					</AppFormControl>
				</Grid>
			</Grid>
			{tableLoading && (
				<Box textAlign={'center'} mt={5}>
					<CircularProgress/>
				</Box>
			)}
			{
				!tableLoading && transactions.length !== 0 &&
				<TransactionHistoryTable transactions={transactions}/>
			}
			{
				!tableLoading && transactions.length === 0 &&
				<Box textAlign={'center'} mt={5}>
					<Typography sx={{color: 'white'}}> No transactions yet</Typography>
				</Box>
			}
		</ContentBox>
	)
}

export default TransactionHistory