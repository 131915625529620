import React from 'react'

import {HamburgerButton, HeaderBox} from './styled'
import {Box, CircularProgress, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ActionButton from "../../../components/ActionButton/ActionButton";
import {useDispatch, useSelector} from "react-redux";
import {isLogoutLoading} from "../../../store/reducers/auth/selectors";
import {logout} from "../../../store/reducers/auth/actions";

const Header = ({ handleClickHamburger}) => {
	const dispatch = useDispatch();
	const loading = useSelector(isLogoutLoading)
	
	return(
		<HeaderBox>
			<HamburgerButton onClick={() => handleClickHamburger()}>
				<MenuIcon/>
			</HamburgerButton>
			<Typography variant='h1' letterSpacing={'0.43px'}>
					Client area
			</Typography>
			<ActionButton
				title={'Logout'}
				sx={{width: '100px'}}
				disabled={loading}
				onClick={() => dispatch(logout(true))}
			/>
		</HeaderBox>
	)
}

export default Header