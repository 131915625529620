import {Button, styled} from "@mui/material";
const HeaderBox = styled('div')`
  //width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 24px;
  background-color:#0D0E0E;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @media (max-width: 375px){
	  h1 {
        font-size: 18px;
      }
  }
`

const HamburgerButton = styled(Button)`
//{color:'black', p:0, minWidth:'unset', display:{xs: 'inline-flex',md: 'none'}}
  color: white;
  min-width:unset;
  display: none;
  padding: 0;
  @media (max-width: 900px){
    display: inline-flex;
  }
`

export { HeaderBox, HamburgerButton }