import React from 'react'
import { AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ReactComponent as TraderIcon} from '../../assets/traderIcon.svg'
import {WebTraderAccordion} from "./styled";

const WebTraderList = ({children}) => {
	return(
		<Box height={'50%'} overflow={'hidden'}>
			<WebTraderAccordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ color: 'white' }}/>}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<TraderIcon/>
					<Typography 
						sx={{ml:'18px', fontFamily: 'Roboto-Medium, sans-serif'}}
					>
						Web Trader
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{children}
				</AccordionDetails>
			</WebTraderAccordion>
		</Box>
	)
}

export default WebTraderList