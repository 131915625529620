import {authApi} from "../../../api/login";
import {pagesForRedirectsApi} from "../../../api/pagesForRedirects";

export const auth = (crmToken) => {
	return async dispatch => {
		try {
			const result = await authApi.login(crmToken)
			const {access_token, refresh_token, expires_in} = result.data
			sessionStorage.setItem('access_token', access_token)
			sessionStorage.setItem('refresh_token', refresh_token)
			sessionStorage.setItem('expires_in', expires_in)

			dispatch({
				type: 'AUTH',
				payload: result.data
			})
		} catch (error) {
			if(error.response.status === 400){
				dispatch(refreshToken2())
			}
			console.log('error auth!!!', error)
		}
	}
}

export const refreshToken2 = () => {
	return async dispatch => {
		try{
			const token = sessionStorage.getItem('refresh_token')

			if (token) {
				const result = await authApi.refreshToken(token)
				const { access_token, refresh_token, expires_in } = result.data
				sessionStorage.setItem('access_token', access_token)
				sessionStorage.setItem('refresh_token', refresh_token)
				sessionStorage.setItem('expires_in', expires_in)
				dispatch({
					type: 'REFRESH_TOKEN',
					payload: result.data
				})
			} else {
				dispatch(logout())
			}
		} catch(error) {
			console.log('error', error)
		}

	}
}

export const updateAccountsForWT = (account) => {
	return dispatch => {
		dispatch({
			type: 'UPDATE_ACCOUNTS_FOR_WT',
			payload: account
		})
	}
}

export const updateIsUserInvalid = (message) => {
	return dispatch => {
		dispatch({
			type: 'SET_IS_USER_INVALID',
			payload: message
		})
	}
}

export const updateIsUserAuth = (isAuth) => {
	return dispatch => {
		dispatch({
			type: 'SET_IS_AUTH',
			payload: isAuth
		})
	}
}

export const logout = (removeToken) => {
	return async dispatch => {
		try {
			dispatch({
				type: 'SET_LOGOUT_LOADING',
				payload: true
			})

			const pageForRedirectResponse = await pagesForRedirectsApi.getLoginPage(window.location.origin);

			if (removeToken) {
				const logoutResponse = await authApi.logout(sessionStorage.getItem('access_token'))

				if (logoutResponse.data.success) {
					sessionStorage.removeItem('access_token')
					sessionStorage.removeItem('refresh_token')
					sessionStorage.removeItem('expires_in')
				}
			}

			window.location.href = pageForRedirectResponse.data.content_site_url || 'https://trenixo.com/login/'

		} catch(error) {
			dispatch({
				type: 'SET_LOGOUT_LOADING',
				payload: false
			})
			console.log('error', error)
		}
	}
}