import React from 'react'
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"

const ManageAccountsLiveTable = ({children}) => {
	
	return(
		<Box mt={'30px'} width={'100%'} sx={{height: {md: 'calc(100% - 85px)', xs: 'calc(100% - 150px)'}}}>
			<TableContainer
				sx={{ height: '100%', overflow: 'auto'}}
			>
				<Table
					sx={{minWidth: 540}} aria-label="withdraw-history-table" stickyHeader
				>
					<TableHead >
						<TableRow>
							<TableCell>Account</TableCell>
							<TableCell align="left">Leverage</TableCell>
							<TableCell align="left">Currency</TableCell>
							<TableCell align="left">Equity</TableCell>
							<TableCell align="left">Balance</TableCell>
							<TableCell align="left">Credit</TableCell>
							<TableCell align="left">Status</TableCell>
							<TableCell align="left">Read only</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						
						{children}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default ManageAccountsLiveTable