import React, { useEffect, useState} from 'react'
import {Box, CircularProgress, Grid, MenuItem, Typography} from "@mui/material";
import AppFormControl from "../../../../../components/AppForm/AppFormControl";
import WithdrawHistoryTable from "./WithdrawHistoryTable";
import {withdrawApi} from "../../../../../api/withdraw";
import {PERIOD_FILTER_VALUES, WITHDRAW_TRANSACTIONS_STATUSES} from "../../../../../constants/constants";
import AppModal from "../../../../../components/AppModal/AppModal";
import AppFormSelectWithDefault from "../../../../../components/AppForm/AppFormSelectWithDefault";
import {useDispatch, useSelector} from "react-redux";
import {getWithdrawTransactionsList} from "../../../../../store/reducers/transactions/actions";
import {selectWithdrawTransactions} from "../../../../../store/reducers/transactions/selectors";
import {getAccountsList} from "../../../../../store/reducers/accounts/actions";
import {selectAllAccounts} from "../../../../../store/reducers/accounts/selectors";
import {refreshToken2} from "../../../../../store/reducers/auth/actions";

const WithdrawHistory = () => {
	const dispatch = useDispatch()
	const [modalContent, setModalContent] = useState({
		title: '',
		text: ''
	})
	
	const [filters, setFilters] = useState({
		periodFilterValue: '',
		statusFilterValue: '',
		accountFilterValue: ''
	})
	const [tableLoading, setTableLoading] = useState(true)
	const [isMounted, setIsMounted] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const accountsList = useSelector(selectAllAccounts).map(item => item.trading_account_id)
	const transactions = useSelector(selectWithdrawTransactions)
	
	const fetchAccounts = async () => {
		try {
			setIsMounted(false)
			await dispatch(getAccountsList())
		} catch (error) {
			console.log(error)
		}finally {
			setIsMounted(true)
		}
	}

	const fetchWithdrawalTransactions = async () => {
		
		try {
			setTableLoading(true)
			await dispatch(getWithdrawTransactionsList(filters.accountFilterValue, filters.periodFilterValue, filters.statusFilterValue))
		} catch (error) {
			console.log(error)
		} finally {
			setTableLoading(false)
		}
	}

	const handleSetFilterValue = (event, filterName) => {
		const filtersCopy = {...filters}
		filtersCopy[filterName] = event.target.value
		setFilters(filtersCopy)
	}

	const handleCancelWithdraw = async (e, transactionId, accountId) => {
		e.preventDefault()
		try {
			const token = sessionStorage.getItem('access_token')
			const res = await withdrawApi.cancelWithdrawTransaction(token, transactionId, accountId)
			await fetchWithdrawalTransactions()
		} catch (error) {
			console.log('error', error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return handleCancelWithdraw(e, transactionId, accountId)
			} else {
				setShowModal(true);
				setModalContent({title: 'Fail', text: error.response.data.details || error.response.data.detail})
			}
		}
	}

	const handleCloseModal = () => {
		setShowModal(false)
	}
	
	useEffect(() => {
		if(isMounted){
			fetchWithdrawalTransactions()
		}else  return
		
	}, [isMounted, filters])

	useEffect(() => {
		fetchAccounts()
	}, [])

	return (
		<>
			<Box height={'85%'}>
				<Grid container columnSpacing={4} rowSpacing={3} columns={12}>
					<Grid item xs={12} md={4}>
						<AppFormControl>
							<AppFormSelectWithDefault
								label={'Period'}
								id={'periodSelect'}
								value={filters.periodFilterValue}
								onChange={(event) => handleSetFilterValue(event, 'periodFilterValue')}
							>
								{ PERIOD_FILTER_VALUES ? PERIOD_FILTER_VALUES.map(option => (
									<MenuItem
										key={option.value}
										value={option.value}
										sx={{fontFamily:'Roboto,sans-serif'}}
										disabled={filters.periodFilterValue === option.value}
									>
										{option.title}
									</MenuItem>
								)) : 'No data'}
							</AppFormSelectWithDefault>
						</AppFormControl>
					</Grid>
					<Grid item xs={12} md={4}>
						<AppFormControl>
							<AppFormSelectWithDefault
								label={'Status'}
								id={'statusSelect'}
								options={WITHDRAW_TRANSACTIONS_STATUSES}
								value={filters.statusFilterValue}
								onChange={(event) => handleSetFilterValue(event, 'statusFilterValue')}
							>
								{ WITHDRAW_TRANSACTIONS_STATUSES ? WITHDRAW_TRANSACTIONS_STATUSES.map(option => (
									<MenuItem
										key={option.value}
										value={option.value}
										sx={{fontFamily:'Roboto,sans-serif'}}
										disabled={filters.statusFilterValue === option.value}
									>
										{option.title}
									</MenuItem>
								)) : 'No data'}
							</AppFormSelectWithDefault>
						</AppFormControl>
					</Grid>
					<Grid item xs={12} md={4}>
						<AppFormControl>
							<AppFormSelectWithDefault
								label={'Account'}
								id={'accountSelect'}
								options={accountsList}
								value={filters.accountFilterValue}
								onChange={(event) => handleSetFilterValue(event, 'accountFilterValue')}
							>
								<MenuItem
									value={''}
									sx={{fontFamily: 'Roboto,sans-serif'}}
									disabled={filters.accountFilterValue === ''}
								>
									All
								</MenuItem>
								{ accountsList ? accountsList.map(option => (
									<MenuItem
										key={option}
										value={option}
										sx={{fontFamily:'Roboto,sans-serif'}}
										disabled={filters.accountFilterValue === option}
									>
										#{option}
									</MenuItem>
								)) : 'No data'}
							</AppFormSelectWithDefault>
						</AppFormControl>
					</Grid>
				</Grid>
				{tableLoading && (
					<Box textAlign={'center'} mt={5}>
						<CircularProgress/>
					</Box>
				)}
				{
					!tableLoading && transactions.length !== 0 &&
					<WithdrawHistoryTable transactionsList={transactions} onCancelWithdraw={handleCancelWithdraw}/>
				}
				{
					!tableLoading && transactions.length === 0 &&
					<Box textAlign={'center'} mt={5}>
						<Typography sx={{color: 'white'}}> No transactions yet</Typography>
					</Box>
				}
				<AppModal isOpen={showModal} title={modalContent.title} errorText={modalContent.text}
				          handleClose={handleCloseModal}/>
			</Box>
		</>
	)
}
export default WithdrawHistory