import {Box, styled} from "@mui/material";

const DashboardBox = styled(Box)`

	background-color: #496166;
  background-size: cover;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
`

export { DashboardBox}